// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    hmr       : false,
    URL : 'https://app.okempleos.com',
    apiURL: 'https://admin.okempleos.com/api/',
    oauthURL: 'https://admin.okempleos.com/oauth/',
    iaURL: 'http://admin.okempleos.com:8080/',
    mode: 'production',
    banners: true,
    slidetime: 10,
    preLaunch: false,
    country_code: 'co',
    logo_horizontal: false,
    country: 'Colombia',
    firebase: {
        apiKey: "AIzaSyAz09CGJamjxoj1x1-i3Q0m4MzGfL28JEo",
        authDomain: "okempleosapp.firebaseapp.com",
        databaseURL: 'okempleosapp-default-rtdb.firebaseio.com',
        projectId: "okempleosapp",
        storageBucket: "okempleosapp.appspot.com",
        messagingSenderId: "734633604976",
        appId: "1:734633604976:web:527824229b64e9d41441b5",
        measurementId: "G-7LWEWFYNE6",
        vapidKey: "BBBwgUWVnzf7KQxRrpnvRsNELd6C7VeaPhdy_vDeS0jU7eYfzwbTXvnlC_zTelOcIAJbXoBPYXuk-8dnDTZmyK0"
      },
    MQTTCONFIG: {
      url: 'mqtt.okempleos.com',
      hostname: 'mqtt.okempleos.com',
      protocol: 'wss',
      port: 8084,
      username: 'admin',
      password: 'emqUL&6%UL&6%g0Zsr3bahjr',
      path: '/mqtt',
      topic_query: 'topic/mqttx',
      topic_update_state: 'topic/mqttx',
      clientID: 'mqttx_597046f4'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
